article.front-article {
    clear: both;
    display: inline-block;
}

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.service-item {
    position: relative;
    color: white;
    text-align: center;
    margin: 30px 0;
    border: 1px solid rgba(0,0,0,0.1);
    color: #2b4364;
    background-color:#EBEBEB;
    font-weight: bold;
    border-radius: 0;
    -webkit-box-shadow: 2px 2px 0 #D5D8DC;
    -moz-box-shadow: 2px 2px 0 #D5D8DC;
    box-shadow: 2px 2px 0 #D5D8DC;

}

.service-item a {
    color:#ebebeb;
    text-transform: uppercase;
    text-decoration: underline;
}

.service-item a:hover {
    color: #ffa500;
}

.service-item .service-intro {
    padding: 50px 0;
}
.service-intro .big-intro{
    font-size: 21px;
    text-transform: uppercase;
}
.service-intro .small-intro{
    font-size: 17px;
    font-weight: 300;
}

.service-item .service-content {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    background-color:#2b4364;
    color: #f9f9f9;
    font-weight: normal;
}
.service-item .service-content .inner-service {
    padding: 10px;
}
.service-item .service-content h3 {
    font-size: 17px;
    color: #f9f9f9;
    text-transform: uppercase;
    display: inline-block;
    padding-bottom: 3px;
    border-bottom: 1px solid white;
    margin-bottom: 5px;
}

.service-item .service-content p{
    font-stretch: expanded;
}

.service-item:hover .service-intro {
    opacity: 0;
    visibility: hidden;
}
.service-item:hover .service-content {
    opacity: 1;
    visibility: visible;
}

/*-----------------------------------------------------------------------------------*/
/* 	11. TIMELINE
/*-----------------------------------------------------------------------------------*/
.timeline {
    position: relative;
    z-index: 1;
}
.timeline:before {
    display: block;
    content: "";
    position: absolute;
    width: 93%;
    height: 100%;
    left: 3px;
    top: 0;    
    border-right: 1px solid rgba(0,0,0,0.1);
    z-index: -1;
}
.timeline .date-title {
    text-align: right;
    margin: 10px 0;
}
.timeline .date-title:first-child {
    margin-top: 0
}
.timeline .date-title span {    
    padding: 11px 20px 10px 20px;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 700;
    color: #f9f9f9;
    background: #2b4364;
    border-radius: 0;
    text-transform: uppercase;
}
.timeline .date-title span a{ color: #f9f9f9;   }
.timeline-item {
    padding-bottom: 10px;
    position: relative;
}
.timeline-item .date {
    color: #999999;
}
.timeline-item .post-content {
    margin: 10px 20px 0 0;
    position: relative;
    padding: 20px;
    background: #FFF;
    border: 1px solid rgba(0,0,0,0.1);
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}
.timeline-item .post-content.classic-view figure {
    margin-bottom: 20px
}
.timeline-item .post-content p {
    margin: 0 0 10px 0
}

.timeline-item .arrow:after,
.timeline-item .arrow:before {
    left: 100%;
    top: 90px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-left: -36px !important;
    z-index: 2;
}
.timeline-item .arrow:after {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: #ffffff;
    border-width: 10px;
    margin-top: -10px;
}
.timeline-item .arrow:before {
    border-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0,0,0,0.1);
    border-width: 11px;
    margin-top: -11px;
}

.timeline-item .post-content:before {
    display: block;
    content: "";
    position: absolute;
    width: 19px;
    height: 19px;
    right: -46px;
    top: 70px;
    background: #2b4364;
    border: 3px solid #f9f9f9;
    border-radius: 50%;
    -webkit-transition: background .3s ease-out;
    transition: background .3s ease-out;
}


/*-----------------------------------------------------------------------------------*/
/*	14. THUMBNAIL OVERLAY
/*-----------------------------------------------------------------------------------*/
figure {
    display: block;
    overflow: hidden;
    position: relative;
}
figure img {
    display: inline;
    width: 100%;
}
figure a .text-overlay {
    opacity: 0;    
    height: 100%;
    position: absolute;
    text-decoration: none;
    width: 100%;
    z-index: 100;
    padding: 50px;
    background: #ffa500;
    transition: all 0.4s;
}
figure a:hover .text-overlay {
    opacity: .9;

}
figure a .text-overlay .info {
    text-align: center;
    top: 50%;
    width: 100%;
    left: 0;
    position: absolute;
    margin-top: -12px;
}
figure a .text-overlay .info span {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    color: #FFF;
}

.post-title{
    margin-bottom: 5px;
    line-height: 20px;
}
.post-title a {
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1px;

}
.post-title a:hover {
    color: #677B97;
    text-decoration: none;
}
