#side-static-header{
    font-size: 14px;
    color: #2b4364;
    font-weight: bold;
    padding: 11px 12px;

}
div.sidebar {
    font-size: 13px;
    padding-top: 25px;

    div.login-menu {
        fieldset.form-group, button {
            margin-bottom: 5px;
        }
    }

    .form-group{
        margin-bottom: 0px;
    }



    ul.sidebar-menu{
        margin-bottom: 20px;



        div.menu-link {
            margin-bottom: 1px;
        }

        .collapse, .collapsing {
            padding: 0;
        }

        a {
            color: #666666;
            text-decoration: none;
        }


        li {
            background-color: #EBEBEB;        
            border:none;
            border-radius: 0;
        }

        li:hover{
            background-color: #ff8e39;
            cursor: pointer;
        }   

        li a{
            padding: 5px 12px;
            border:none;
            border-radius: 0;
            margin: 0 0;
        }
        li > a:hover , li > a:focus, li.active > a, li.active > a:hover, li.active > a:focus{
            border-color:none; 
            background-color: #ff8e39; 
            border:none;
            border-radius: 0;
            margin: 0 0;
            color: #FFFFFF;
        } 
    }
}