div .menu-link{

}

div .menu-folder{
    background-color: #F3F2F2;
}

div .list-group button{
    margin-left: 10px;
}

div .item-name{
    padding-left: 10px;
    font-weight: bold;
}

nav.sidebar-admin .navbar-nav > li > a{
    text-decoration: none;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #e7e7e7;
}

nav.sidebar-admin .navbar-nav > li:active{
    background-color: #425A7B;    
}
nav.sidebar-admin .navbar-nav > .open > a,nav.sidebar-admin .navbar-nav > .open > a:hover,nav.sidebar-admin .navbar-nav > .open > a:focus{
    background-color: #425A7B;
}

/*Edition Menu*/
#edition_menu {
    border: 2px solid #2b4364;
    color: #2b4364;
    font-weight: bold;
    border-radius: 0;
    padding: 11px 12px;
    height: 41px;
}

#edition_menu option{
    color: #2b4364;
}

/**Side bar admin */

nav.sidebar-admin a{
    padding-right: 13px;
}

nav.sidebar-admin .navbar-nav > li:first-child{
    border-top: 1px #e5e5e5 solid;
}

nav.sidebar-admin .navbar-nav > li{
    border-bottom: 1px #e5e5e5 solid;
    width: 100%;   
}

nav.sidebar-admin .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;

    background-color: #C5CDD8;

    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

nav.sidebar-admin .dropdown-menu > li > a {
    text-decoration: none;
    padding-left: 30px;
    color: #2b4364;
    padding-top: 7px;
    padding-bottom: 7px;
}

nav.sidebar-admin .navbar-collapse{
    padding: 0 0px 0 0px;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: #777;
}

nav.sidebar-admin ul.dropdown-menu{
    padding: 0px;
}

nav.sidebar-admin .dropdown-menu > .active > a, nav.sidebar-admin .dropdown-menu > .active > a:hover, nav.sidebar-admin .dropdown-menu > .active > a:focus{
    color: #555;
    background-color: #e7e7e7;    
}

nav.sidebar-admin .navbar-nav .open .dropdown-menu>li>a:hover, nav.sidebar-admin .navbar-nav .open .dropdown-menu>li>a:focus, 
nav.sidebar-admin .navbar-nav .open .dropdown-menu>li>a:active {
    color: #677B97;
    background-color: transparent;
}


